.GobiSection {
    @mixin studyPageSectionPadding;
    background: var(--blackBaseColor);
    color: var(--whiteBaseColor);
}

.OverlayHeading {
    @mixin h30;
    font-weight: 600;
    margin: 0 0 24px;
}

.Title {
    @mixin sectionHeading;
    margin-bottom: 64px;

    @media (--tablet) {
        margin-bottom: 80px;
    }
}

.Content {
    @media (--tablet) {
        display: grid;
        gap: calc(var(--gutterWidth));
        grid-template-columns: repeat(12, 1fr);

        & .TextSubsection {
            grid-column: 1 / span 6;
        }

        & .Separator {
            grid-column: 8;
            justify-self: center;
        }

        & .VideoSubsection {
            grid-column: 10 / span 3;
        }
    }
}

.Separator {
    border-bottom: 1px solid var(--whiteBaseColor);
    margin-bottom: 48px;

    @media (--tablet) {
        border-bottom: none;
        border-right: 1px solid var(--whiteBaseColor);
        margin-bottom: 0;
    }
}

.TextSubsection {
    padding-bottom: 48px;

    @media (--tablet) {
        padding-bottom: 0;
    }
}

.IntroText {
    @mixin hyphenateWords manual;
    @mixin p30;
    margin-bottom: 80px;

    @media (--tablet) {
        @mixin p10;
    }
}

.VideoSubsection {
    padding: 0 32px;

    @media (--tablet) {
        padding: 0;
    }
}

.OverlayButton {
    @mixin buttonScaleAnimationOnChild .OverlayIcon, 1.2, 0.95;

    @mixin onFocus {
        @mixin focusVisible;
    }
    margin-left: auto;
    padding: 8px;
}

.OverlayIcon {
    @mixin fixedRatioSvgIcon 32px, var(--whiteBaseColor);
}

.Thumbnail {
    @mixin aspectRatio 16/9;
}
