.GobiStory {
    position: relative;
}

.PlayWrapper {
    @mixin centerElements;
    @mixin centerItself;
    position: absolute;
    width: 64px;
    height: 64px;
    z-index: 1;
    color: var(--whiteBaseColor);
    background: rgb(var(--whiteBaseRgb) / 30%);
    border-radius: 50%;
}

.Play {
    @mixin fixedRatioSvgIcon 32px;
}

.OpenButton {
    @mixin absoluteParentSize;

    @mixin onFocus {
        @mixin focusVisible -4px;
    }
    z-index: 2;
}

.ErrorContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 32px;
    background: var(--blackLighten20Color);

    @media (--fromTabletToDesktop) {
        padding: 16px;

        & .ErrorIcon {
            @mixin fixedRatioSvgIcon 48px;
        }

        & .ErrorMessage {
            @mixin p20;
        }
    }
}

.ErrorIcon {
    @mixin fixedRatioSvgIcon 64px;
    margin-bottom: 16px;
}

.ErrorMessage {
    @mixin h50;
}

.Variant--rounded {
    @mixin themeRoundedCornersMedium;
    overflow: hidden;

    & .OpenButton {
        @mixin themeRoundedCornersMedium;
    }
}
